<template>
  <div class="c-h5p__container" ref="h5pWrapper">
    <VH5P
      v-if="canLoad && data && data.content?.resourceId"
      :id="data.content.resourceId"
      tabindex="0"
      width="1088"
      height="755"
    >
      <template #instructions>
        <div v-if="data.content.instructions" class="c-h5p__instructions" v-md-html="data.content.instructions" />
      </template>
    </VH5P>
    <div v-else class="c-h5p__loader">
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import VH5P from '@forlagshuset/v-h5p'

export default {
  name: 'H5PWrapper',

  components: {
    VH5P
  },

  props: {
    data: Object
  },

  setup() {
    const canLoad = ref(false)
    const io = ref(null)
    const h5pWrapper = ref(null)

    onMounted(() => {
      if (window.IntersectionObserver) {
        io.value = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !canLoad.value) {
              canLoad.value = true
              io.value.unobserve(h5pWrapper.value)
            }
          })
        })

        io.value.observe(h5pWrapper.value)
      } else {
        canLoad.value = true
      }
    })

    return {
      canLoad,
      io,
      h5pWrapper
    }
  }
}
</script>

<style lang="scss">
.c-h5p__container {
  display: block;
  padding: 1rem;
  margin: 1.375rem auto;

  @include bp(medium) {
    padding: 2.5rem 3.125rem;
  }
}

.c-h5p__loader {
  height: 0;
  padding-bottom: 36%; // approx. size of exercise in economy portal
}

.c-h5p__instructions {
  margin-bottom: 2rem;
}
</style>